import { http } from '@/services'
import { reactive } from 'vue'

export const genreStore = {
  state: reactive<{ genres: Genre[] }>({
    genres: []
  }),
  async fetchAll() {
    this.state.genres = await http().get<Genre[]>('genres')
    return this.state.genres
  },
  fetchOne: async (name: string) => await http().get<Genre>(`genres/${name}`)
}
