import QueueScreen from '@/components/screens/QueueScreen.vue'
import { appStore } from '@/stores'

export default {
    path: '/queue',
    name: 'Queue',
    component: QueueScreen,
    meta: {
        async asyncData() {
            appStore.state.seo.title = '福音诗歌网'
        }
    }
}