import RecentlyPlayedScreen from '@/components/screens/RecentlyPlayedScreen.vue'

import base from '@/base'
import { useMessageToaster } from '@/composables'
import { appStore, recentlyPlayedStore } from '@/stores'
import { logger } from '@/utils'

export default {
    path: '/recently-played',
    name: 'RecentlyPlayed',
    component: RecentlyPlayedScreen,
    meta: {
        async asyncData() {
            appStore.state.seo.title = '福音诗歌网'
            try {
                appStore.state.loading = true
                await recentlyPlayedStore.fetch()
            } catch (e) {
                if (!base.isSSR) {
                    useMessageToaster().toastError('数据加载失败。')
                    logger.error(e)
                }
            } finally {
                appStore.state.loading = false
            }
        }
    }
}