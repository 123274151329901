import ArtistListScreen from '@/components/screens/ArtistListScreen.vue'
const ArtistScreen = () => import('@/components/screens/ArtistScreen.vue')
import base from '@/base'
import { useMessageToaster } from '@/composables'
import { appStore, artistStore, songStore } from '@/stores'
import { logger } from '@/utils'
import { RouteLocationNormalized } from 'vue-router'

export default [
    {
        path: '/artists',
        name: 'Artists',
        component: ArtistListScreen,
        meta: {
            async asyncData() {
                appStore.state.seo.title = '福音诗歌网'
                try {
                    appStore.state.loading = true
                    appStore.state.page = await artistStore.paginate(1)
                } catch (e) {
                    if (!base.isSSR) {
                        useMessageToaster().toastError('数据加载失败。')
                        logger.error(e)
                    }
                } finally {
                    appStore.state.loading = false
                }
            }
        }
    },
    {
        path: '/artist/:id',
        name: 'Artist',
        component: ArtistScreen,
        meta: {
            async asyncData(route: RouteLocationNormalized) {
                appStore.state.seo.title = '福音诗歌网'
                let id = parseInt(route.params.id as string)
                if (!id) return

                appStore.state.loading = true
                appStore.state.artist = undefined
                try {
                    [appStore.state.artist, appStore.state.songs] = await Promise.all([
                        artistStore.resolve(id),
                        songStore.fetchForArtist(id)
                    ])
                    appStore.state.seo.title = appStore.state.artist?.name + '-福音诗歌网'
                } catch (e) {
                    if (!base.isSSR) {
                        useMessageToaster().toastError('数据加载失败。')
                        logger.error(e)
                    }
                } finally {
                    appStore.state.loading = false
                }
            }
        }
    },
]