import base from '@/base'
import AlbumListScreen from '@/components/screens/AlbumListScreen.vue'
const AlbumScreen = () => import('@/components/screens/AlbumScreen.vue')
import { RouteLocationNormalized } from 'vue-router'
import { useMessageToaster, useDialogBox } from '@/composables'
import { albumStore, appStore, songStore } from '@/stores'
import { logger } from '@/utils'

export default [
    {
        path: '/albums',
        name: 'Albums',
        component: AlbumListScreen,
        meta: {
            async asyncData() {
                appStore.state.seo.title = '福音诗歌网'
                try {
                    appStore.state.loading = true
                    appStore.state.page = await albumStore.paginate(1)
                } catch (e) {
                    if (!base.isSSR) {
                        useMessageToaster().toastError('数据加载失败。')
                        logger.error(e)
                    }
                } finally {
                    appStore.state.loading = false
                }
            }
        }
    },
    {
        path: '/album/:id',
        name: 'Album',
        component: AlbumScreen,
        meta: {
            async asyncData(route: RouteLocationNormalized) {
                appStore.state.seo.title = '福音诗歌网'
                let id = parseInt(route.params.id as string)
                if (!id) return

                appStore.state.loading = true
                appStore.state.album = undefined
                try {
                    [appStore.state.album, appStore.state.songs] = await Promise.all([
                        albumStore.resolve(id),
                        songStore.fetchForAlbum(id)
                    ])
                    appStore.state.seo.title = appStore.state.album?.name + '-福音诗歌网'
                } catch (error) {
                    if (!base.isSSR) {
                        logger.error(error)
                        useDialogBox().showErrorDialog('数据加载失败，请重试。', '错误')
                    }
                } finally {
                    appStore.state.loading = false
                }
            }
        }
    },
]