import base from '@/base'
import PlaylistScreen from '@/components/screens/PlaylistScreen.vue'
import { useDialogBox } from '@/composables'
import { appStore, playlistStore, songStore } from '@/stores'
import { logger } from '@/utils'
import { RouteLocationNormalized } from 'vue-router'

export default {
    path: '/playlist/:id',
    name: 'Playlist',
    component: PlaylistScreen,
    meta: {
        async asyncData(route: RouteLocationNormalized) {
            appStore.state.seo.title = '福音诗歌网'
            let id = parseInt(route.params.id as string)
            if (!id) return

            appStore.state.loading = true
            appStore.state.album = undefined
            try {
                appStore.state.playlist = playlistStore.byId(id)
                if (appStore.state.playlist) {
                    appStore.state.songs = await songStore.fetchForPlaylist(appStore.state.playlist, false)
                }
            } catch (error) {
                if (!base.isSSR) {
                    logger.error(error)
                    useDialogBox().showErrorDialog('数据加载失败，请重试。', '错误')
                }
            } finally {
                appStore.state.loading = false
            }
        }
    }
}