import { clickaway, focus, hideBrokenIcon, overflowFade, tooltip } from '@/directives'
import * as stores from '@/stores'
import 'nouislider/distribute/nouislider.min.css'
import { Component, createSSRApp } from 'vue'
import { RouteLocationNormalized, RouteRecordRaw, Router, createRouter, createWebHistory } from 'vue-router'
import Application from './main'

class ClientApplication extends Application {
    constructor() {
        super()
        this.app
            .directive('koel-focus', focus)
            .directive('koel-clickaway', clickaway)
            .directive('koel-tooltip', tooltip)
            .directive('koel-hide-broken-icon', hideBrokenIcon)
            .directive('koel-overflow-fade', overflowFade)
    }
    createVueApp(rootComponent: Component) {
        return createSSRApp(rootComponent)
    }

    createRouter(routes: RouteRecordRaw[]): Router {
        const router = createRouter({
            routes, history: createWebHistory(),
            scrollBehavior(to, from, savedPosition) {
                if (savedPosition) {
                    return savedPosition
                } else {
                    return { left: 0, top: 0 }
                }
            }
        })
        if (process.env.NODE_ENV == "development") {
            router.beforeResolve(this.beforeRouterResolve.bind(this))
        }
        return router
    }

    async render() {
        if (window.__ssr_init_state__) {
            for (let key in window.__ssr_init_state__) {
                let state = window.__ssr_init_state__[key]
                try {
                    if (stores[key].state) {
                        Object.assign(stores[key].state, state)
                    }
                } catch (e) {
                    console.error('init fault', key, stores[key].state, state)
                }
            }
        }
        // let session = window.localStorage.getItem("session");
        // if (session) {
        //     this.store.dispatch('loadSession', session);
        // }
        // this.store.subscribeAction(action => {
        //     if (action.type == 'saveSession') {
        //         if (action.payload) {
        //             console.info('save session', action.payload)
        //             window.localStorage.setItem('session', action.payload)
        //         } else {
        //             console.info('clear session')
        //             window.localStorage.removeItem('session')
        //         }
        //     }
        // })
        await this.router.isReady()
        if (process.env.NODE_ENV != "development") {
            this.router.beforeResolve(this.beforeRouterResolve.bind(this))
        }
        this.app.mount('#app')
    }

    async loadAsyncData(to: RouteLocationNormalized, actions: Function[]) {
        try {
            await super.loadAsyncData(to, actions)
            document.title = stores.appStore.state.seo.title
        } catch (e) {
            console.error(e)
        }
    }
}

new ClientApplication().render()

navigator.serviceWorker?.register('./sw.js')