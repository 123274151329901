import { reactive, ref } from 'vue'
export interface AppState {
    loading: boolean,
    page: number | null,
    album: Album | undefined,
    artist: Artist | undefined,
    playlist: Playlist | undefined,
    genre: Genre | undefined,
    songs: Song[],
    seo: { title: string, keywords: string, description: string },
}

export const appStore = {
    state: reactive<AppState>({
        loading: false,
        page: 1,
        album: undefined,
        artist: undefined,
        playlist: undefined,
        genre: undefined,
        songs: [],
        seo: {
            title: '福音诗歌网',
            keywords: '福音诗歌网',
            description: '福音诗歌网'
        }
    })
}