import { Events } from '@/config'

export type ListenerSignature<L> = {
    [E in keyof L]: (...args: any[]) => any;
};

export type DefaultListener = {
    [k: string]: (...args: any[]) => any;
};

export class TypedEmitter<L extends ListenerSignature<L> = DefaultListener> {
    static defaultMaxListeners: number;
    private _listeners: Record<any, Function[]> = {}

    removeListener<U extends keyof L>(event: U, listener: L[U]): this {
        if (this._listeners[event]) {
            let i = this._listeners[event].indexOf(listener)
            if (i >= 0) {
                this._listeners[event].splice(i, 1)
                if (this._listeners[event].length == 0) {
                    delete this._listeners[event]
                }
            }
        }
        return this
    }
    removeAllListeners(event?: keyof L): this {
        if (event) {
            delete this._listeners[event]
        } else {
            this._listeners = {}
        }
        return this
    }
    on<U extends keyof L>(event: U, listener: L[U]): this {
        if (!this._listeners[event]) {
            this._listeners[event] = []
        }
        this._listeners[event].push(listener)
        return this
    }
    emit<U extends keyof L>(event: U, ...args: Parameters<L[U]>): boolean {
        if (this._listeners[event]) {
            let listeners = this._listeners[event].slice()
            for (let listener of listeners) {
                try {
                    listener(...args)
                } catch (e) { }
            }
            return true
        }
        return false
    }
    setMaxListeners(n: number): this {
        return this
    }
}


const eventBus = new TypedEmitter<Events>()
eventBus.setMaxListeners(100)
export { eventBus }
