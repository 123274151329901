import base from '@/base'
import AllSongsScreen from '@/components/screens/AllSongsScreen.vue'

import { useMessageToaster } from '@/composables'
import { appStore, songStore } from '@/stores'
import { logger } from '@/utils'

export default {
    path: '/songs',
    name: 'Songs',
    component: AllSongsScreen,
    meta: {
        async asyncData() {
            appStore.state.seo.title = '福音诗歌网'
            try {
                appStore.state.loading = true
                appStore.state.page = await songStore.paginate('title', 'asc', 1)
            } catch (e) {
                if (!base.isSSR) {
                    const { toastError } = useMessageToaster()
                    toastError('数据加载失败。')
                    logger.error(e)
                }
            } finally {
                appStore.state.loading = false
            }
        }
    }
}