import FavoritesScreen from '@/components/screens/FavoritesScreen.vue'

import base from '@/base'
import { useMessageToaster } from '@/composables'
import { appStore, favoriteStore } from '@/stores'
import { logger } from '@/utils'

export default {
    path: '/favorites',
    name: 'Favorites',
    component: FavoritesScreen,
    meta: {
        async asyncData() {
            appStore.state.seo.title = '福音诗歌网'
            try {
                appStore.state.loading = true
                await favoriteStore.fetch()
            } catch (e) {
                if (!base.isSSR) {
                    useMessageToaster().toastError('数据加载失败。')
                    logger.error(e)
                }
            } finally {
                appStore.state.loading = false
            }
        }
    }
}