import home from "./home"
import notfound from "./notfound"
import queue from "./queue"
import songs from "./songs"
import albums from "./albums"
import artists from "./artists"
import favorites from "./favorites"
import recently from "./recently"
import search from "./search"
import profile from "./profile"
import visualizer from "./visualizer"
import playlist from "./playlist"
import genres from "./genres"

export default [
    home,
    notfound,
    queue,
    songs,
    ...albums,
    ...artists,
    ...genres,
    favorites,
    recently,
    ...search,
    profile,
    visualizer,
    playlist,
]