const ProfileScreen = () => import('@/components/screens/ProfileScreen.vue')
import { appStore } from '@/stores'
export default {
    path: '/profile',
    name: 'Profile',
    component: ProfileScreen,
    meta: {
        async asyncData() {
            appStore.state.seo.title = '福音诗歌网'
        }
    }

}