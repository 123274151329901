import { computed, onUnmounted, ref } from 'vue'
import base from '@/base'

export const useNetworkStatus = () => {
  const navigatorOnLine = base.isSSR ? true : navigator.onLine
  const online = ref(navigatorOnLine)
  const offline = computed(() => !online.value)

  const updateOnlineStatus = () => (online.value = navigatorOnLine)

  if (!base.isSSR) {
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)
    onUnmounted(() => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    })
  }

  return {
    online,
    offline
  }
}
