const isDev = process.env.NODE_ENV == "development"
const isSSR = (function () {
    try {
        window.document
        return false
    } catch (e) {
        return true
    }
})()

export default {
    isSSR, isDev,
    apiDomain: 'https://sg-api.fuyinweb.net/api',

}