import base from '@/base'
import GenreListScreen from '@/components/screens/GenreListScreen.vue'
import { useDialogBox, useMessageToaster } from '@/composables'
import { appStore, genreStore, songStore } from '@/stores'
import { logger } from '@/utils'
import { RouteLocationNormalized } from 'vue-router'
const GenreScreen = () => import('@/components/screens/GenreScreen.vue')
export default [
    {
        path: '/genres',
        name: 'Genres',
        component: GenreListScreen,
        meta: {
            async asyncData() {
                appStore.state.seo.title = '福音诗歌网'
                try {
                    appStore.state.loading = true
                    await genreStore.fetchAll()
                } catch (e) {
                    if (!base.isSSR) {
                        useMessageToaster().toastError('数据加载失败。')
                        logger.error(e)
                    }
                } finally {
                    appStore.state.loading = false
                }
            }
        }
    },
    {
        path: '/genres/:name',
        name: 'Genre',
        component: GenreScreen,
        meta: {
            async asyncData(route: RouteLocationNormalized) {
                appStore.state.seo.title = '福音诗歌网'
                let name = encodeURIComponent(route.params.name as string)
                if (!name) return

                appStore.state.loading = true
                appStore.state.genre = undefined
                appStore.state.page = 1
                appStore.state.songs = []
                try {
                    let fetched

                    [appStore.state.genre, fetched] = await Promise.all([
                        genreStore.fetchOne(name),
                        songStore.paginateForGenre(name, 'title', 'asc', 1)
                    ])

                    appStore.state.page = fetched.nextPage
                    appStore.state.songs = fetched.songs
                } catch (error) {
                    if (!base.isSSR) {
                        logger.error(error)
                        useDialogBox().showErrorDialog('数据加载失败，请重试。', '错误')
                    }
                } finally {
                    appStore.state.loading = false
                }
            }
        }
    },
]