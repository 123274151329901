import base from '@/base'
import SearchExcerptsScreen from '@/components/screens/search/SearchExcerptsScreen.vue'
import { useMessageToaster } from '@/composables'
import { appStore, searchStore } from '@/stores'
import { logger } from '@/utils'
import { RouteLocationNormalized } from 'vue-router'
const SearchSongResultsScreen = () => import('@/components/screens/search/SearchSongResultsScreen.vue')

export default [
    {
        path: '/search',
        name: 'Search.Excerpt',
        component: SearchExcerptsScreen,
        meta: {
            async asyncData() {
                appStore.state.seo.title = '福音诗歌网'
            }
        }
    },
    {
        path: '/search/songs',
        name: 'Search.Songs',
        component: SearchSongResultsScreen,
        meta: {
            async asyncData(route: RouteLocationNormalized) {
                appStore.state.seo.title = '福音诗歌网'
                let q = route.query.q as string || ''
                if (q) {
                    try {
                        appStore.state.loading = true
                        await searchStore.songSearch(q)
                    } catch (e) {
                        if (!base.isSSR) {
                            useMessageToaster().toastError('数据加载失败。')
                            logger.error(e)
                        }
                    } finally {
                        appStore.state.loading = false
                    }
                }
            }
        }
    },
]