import { commonStore } from '@/stores'
import { App, Component } from 'vue'
import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw, Router } from 'vue-router'
import Root from './App.vue'
import routes from './routes'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

export default class Application {
    readonly app: App
    readonly router: Router

    constructor() {
        this.app = this.createVueApp(Root);
        this.router = this.createRouter(routes)
        this.app.use(this.router)
        this.app
            .component('icon', FontAwesomeIcon)
            .component('icon-layers', FontAwesomeLayers)
    }

    createVueApp(rootComponent: Component): App {
        throw new Error('unimplements this method')
    }

    createRouter(routes: RouteRecordRaw[]): Router {
        throw new Error('unimplements this method')
    }

    async beforeRouterResolve(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        let actions = to.matched.map(item => item.meta.asyncData).filter(action => action) as Function[]
        await this.loadAsyncData(to, actions)
        next()
    }

    async loadAsyncData(to: RouteLocationNormalized, actions: Function[]) {
        await commonStore.init()
        try {
            //     this.store.commit('updateP404', false)
            await Promise.all(actions.map(item => item(to)))
        } catch (e) {
            //     this.store.commit('updateP404', true)
        }
    }
}