const VisualizerScreen = () => import('@/components/screens/VisualizerScreen.vue')
import { appStore } from '@/stores'
export default {
    path: '/visualizer',
    name: 'Visualizer',
    component: VisualizerScreen,
    meta: {
        async asyncData() {
            appStore.state.seo.title = '福音诗歌网'
        }
    }
}