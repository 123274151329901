const NotFoundScreen = () => import('@/components/screens/NotFoundScreen.vue')
import { appStore } from '@/stores'
export default {
    path: '/404',
    name: '404',
    component: NotFoundScreen,
    meta: {
        async asyncData() {
            appStore.state.seo.title = '福音诗歌网'
        }
    }
}