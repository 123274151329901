import base from '@/base'
import HomeScreen from '@/components/screens/HomeScreen.vue'

import { useDialogBox } from '@/composables'
import { appStore, overviewStore } from '@/stores'
import { logger } from '@/utils'

export default {
    path: '/',
    name: 'Home',
    component: HomeScreen,
    meta: {
        async asyncData() {
            appStore.state.seo.title = '福音诗歌网'
            try {
                appStore.state.loading = true
                await overviewStore.init()
            } catch (e) {
                if (!base.isSSR) {
                    const { showErrorDialog } = useDialogBox()
                    showErrorDialog('数据加载失败，请重试。', '错误')
                    logger.error(e)
                }
            } finally {
                appStore.state.loading = false
            }
        }
    }
}