import base from "@/base"
/**
 * Check if AudioContext is supported by the current browser.
 */
export const isAudioContextSupported = true

/**
 * Checks if the browser supports reading (and thus uploading) a whole directory.
 */
export const isDirectoryReadingSupported = false

export const isFullscreenSupported = () => base.isSSR ? false : Boolean(document.fullscreenEnabled)
