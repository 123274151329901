import { Router, useRouter as useRouter0, useRoute } from 'vue-router'

let router: Router

export const useRouter = () => {
  router = router || useRouter0()
  const route = useRoute()

  const isCurrentScreen = (...screens: string[]) => screens.includes(router.currentRoute.value.name as string)

  return {
    route,
    isCurrentScreen,
    go: router.push.bind(router),
  }
}
